@import '../../../styles/colors';

.compWrap {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 300;
  margin-left: 0;
  margin-bottom: 12px;
  color: $dark-grey;
}

.input {
  font-size: 18px;
  color: $white;
  min-width: 300px;
  padding: 16px;
  background-color: $grey;
  border: 1px solid $white;

  &:focus {
    border: 1px solid $yellow;
    outline: none;
  }
}

.hide {
  display: none;
}
